#top{
	textarea::-webkit-scrollbar,
	.ep-posts-grid::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	
	.header-logo{
		margin-right: auto !important;
	}

	.has-triangle::after{
		display: none;
	}

	.team-slider .ep-content-slider .previous{
		left: calc( 87.5% - 105px );
		bottom: -19px;
	}

	.get-in-touch-item-grid{
		.ep-item-grid-icon{
			font-size: 30px;
		}
	}
}